import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { FALLBACK_IMAGE_URL } from "@/app/constants.mjs";
import Container from "@/shared/ContentContainer.jsx";
import ListItemsTruncated from "@/shared/ListItemsTruncated.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import SharedProfile, {
  MatchList,
  MatchTile,
  UnknownPlayerHeader,
} from "@/shared/Profile.jsx";
import {
  ProfileColumn,
  ProfileLayout,
  ProfileMatchlist,
} from "@/shared/Profile.style.jsx";
import ProfileRank from "@/shared/ProfileRank.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const CHARACTER_STATS = [
  {
    wins: 3,
    losses: 6,
    kills: 7,
    deaths: 10,
    assists: 12,
    name: "Bob the Builder",
  },
  {
    wins: 8,
    losses: 3,
    kills: 18,
    deaths: 8,
    assists: 14,
    name: "Winnie the Pooh",
  },
  {
    wins: 2,
    losses: 1,
    kills: 5,
    deaths: 2,
    assists: 4,
    name: "Dora the Explorer",
  },
  {
    wins: 1,
    losses: 0,
    kills: 2,
    deaths: 3,
    assists: 2,
    name: "Tony the Tiger",
  },
  {
    wins: 0,
    losses: 1,
    kills: 1,
    deaths: 3,
    assists: 1,
    name: "Lightning McQueen",
  },
];

const UnknownMatch = styled("div")`
  display: flex;
  padding: var(--sp-4);
  justify-content: space-between;
  .outcome {
    font-weight: bold;
    &.win {
      color: var(--blue);
    }
    &.loss {
      color: var(--red);
    }
  }
  .time-ago {
    color: var(--shade2);
    font-size: 0.875em;
  }
`;

function Profile() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const {
    parameters: [profileId],
  } = useRoute((prev, next) => prev?.currentPath === next.currentPath);

  const profile = state.unknown.profiles[profileId];
  const matchList = state.unknown.matchLists[profileId];

  const profileError = profile instanceof Error ? profile : null;

  const displayName = profile?.name || profileId;

  const baseUrl = `/unknown/profile/${profile?.name}`;

  return (
    <ProfileLayout>
      {!profileError ? (
        <PageHeader
          imageRound
          title={displayName}
          image={profile?.icon || FALLBACK_IMAGE_URL}
          accentText={98}
          style={{ gridArea: "header" }}
          links={[
            {
              url: baseUrl,
              text: t("common:navigation.overview", "Overview"),
            },
          ]}
        />
      ) : (
        <UnknownPlayerHeader />
      )}
      <Container>
        <SharedProfile>
          <ProfileColumn className="flex column gap-sp-4 sidebar">
            <ProfileRank
              title="Rank"
              // image="image-path-here.webp"
              imageComponent={
                <div
                  style={{
                    height: 80,
                    width: 80,
                    background: "var(--game-accent-color)",
                    borderRadius: "var(--br-xl)",
                  }}
                />
              }
              name="Iron 2"
              points="420"
              percentFill={0.72}
              color="var(--game-accent-color)"
              wins={3}
              losses={6}
            />
            <ProfileRank.Accordion
              ranksList={[
                {
                  // queueIcon: QueueIcon && <QueueIcon />,
                  label: "Losers Queue",
                  rankName: "Platinum 2",
                  points: 252,
                  percentFill: 0.31,
                  wins: 5,
                  losses: 78,
                  summaryColor: "var(--game-accent-color)",
                  barColor: "var(--game-accent-color)",
                },
                {
                  // queueIcon: QueueIcon && <QueueIcon />,
                  label: "FFA",
                  rankName: "Silver4 ",
                  points: 13,
                  percentFill: 0.84,
                  wins: 2,
                  losses: 4,
                  summaryColor: "var(--game-accent-color)",
                  barColor: "var(--game-accent-color)",
                },
              ]}
            />
            <ListItemsTruncated
              title="Character Performance"
              list={CHARACTER_STATS}
              itemLeftContent={(item) => {
                const { name, kills, deaths, assists } = item;
                const kda = (kills + assists) / (deaths || 1);
                return (
                  <>
                    <div
                      style={{
                        width: 40,
                        height: 40,
                        background: "var(--shade5)",
                      }}
                    />
                    <div>
                      <p className="type-callout--bold">{name}</p>
                      <p className="type-caption--semi">
                        {t("lol:matchHistory.kda", "{{kda}} KDA", {
                          kda: kda.toLocaleString(getLocale(), {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }),
                        })}
                      </p>
                    </div>
                  </>
                );
              }}
              itemRightContent={(item) => {
                const { wins, losses } = item;
                const gameCount = wins + losses;
                const winrate = wins / (gameCount || 1);

                return (
                  <div>
                    <p className="type-caption">
                      {winrate.toLocaleString(getLocale(), {
                        style: "percent",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </p>
                    <p
                      className="type-caption"
                      style={{ color: "var(--shade2)" }}
                    >
                      {t(
                        "lol:matchHistory.winsAndLossesWithHypen",
                        "{{wins}}W-{{losses}}L",
                        {
                          wins: wins ?? 0,
                          losses: (gameCount ?? 0) - (wins ?? 0),
                        },
                      )}
                    </p>
                  </div>
                );
              }}
              itemLinkFormat={(_item) => {
                return `/unknown/profile/item-thing-here`;
              }}
            />
          </ProfileColumn>
          <ProfileColumn className="main">
            <UnknownMatchColumn matchList={matchList} />
          </ProfileColumn>
        </SharedProfile>
      </Container>
    </ProfileLayout>
  );
}

function UnknownMatchColumn({ matchList }) {
  const state = useSnapshot(readState);
  const victoryLabel = "Victory";
  const lossLabel = "Loss";
  const matchListLabel = "Matchlist Header";

  return (
    <>
      <Card>{matchListLabel}</Card>
      <ProfileMatchlist>
        <MatchList matchList={matchList}>
          {(matchList?.matches || []).map((matchlistItem) => {
            const match = state.unknown.matches[matchlistItem.id]; // eslint-disable-line valtio/state-snapshot-rule
            const hasMatch = match && !(match instanceof Error);
            const matchRoute = `/unknown/match/${matchlistItem.id}`;

            return (
              <MatchTile
                height={70}
                id={matchlistItem.id}
                key={matchlistItem.id}
                match={match}
                matchRoute={matchRoute}
              >
                {hasMatch ? (
                  <UnknownMatch>
                    <span
                      className={`outcome ${match.isWinner ? "win" : "loss"}`}
                    >
                      {match.isWinner ? victoryLabel : lossLabel}
                    </span>
                    <span className="time-ago">
                      <TimeAgo date={match.timestamp} />
                    </span>
                  </UnknownMatch>
                ) : null}
              </MatchTile>
            );
          })}
        </MatchList>
      </ProfileMatchlist>
    </>
  );
}

export function meta([name]) {
  return {
    title: ["common:unknownProfile", "{{name}} - unknown profile", { name }],
    description: ["common:unknownProfileDescription", "player stats"],
  };
}

export default Profile;
